import React from 'react'
import styled from 'styled-components'

const LandingPageCarouselCustomersSliderWrapper = styled.div`
  display: grid;
  place-items: center;
  gap: 20px;
  position: relative;
  width: 90%;
  margin: 0 auto;
  overflow: hidden;

  .CustomersCustomersSlider {
    height: auto;
    margin: auto;
    position: relative;
    width: 100%;
    display: flex;
    place-items: center;
    overflow: hidden;
  }

  .CustomersCustomersSlide-Track,
  .CustomersCustomersSlide-Track-Reverse {
    display: flex;
    justify-content: center;
    width: max-content;
    animation: scrollLeft 190s linear infinite;
    margin-bottom: 5px;
  }

  .CustomersCustomersSlide-Track-Reverse {
    animation: scrollRight 190s linear infinite;
    transform: translateX(-50%); // Start the reverse track halfway
    margin-top: 5px;
    margin-bottom: 40px;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  .CustomersCustomersSlide-Track:hover,
  .CustomersCustomersSlide-Track-Reverse:hover {
    animation-play-state: paused;
  }
  @keyframes scrollLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  @keyframes scrollRight {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .CustomersSlide {
    margin: 4px;
    display: flex;
    align-items: center;
    padding: 0px 22px;
    cursor: pointer;
  }

  img {
    max-width: 12vw;
    max-height: 8vh;
    transition: transform 1s;
    object-fit: cover;
    filter: brightness(0) invert(1);
  }

  img:hover {
    filter: brightness(1) invert(0);
  }

  @media (max-width: 1024px) {
    img {
      max-width: 20vw;
      max-height: 10vh;
    }
  }
`

function AppLandingPageCarousel() {
  const images = [
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/TRYSTAR_logo.png',
      alt: 'TRYSTAR-Logo',
      link: 'https://www.trystar.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/midwest-vision-partners-logo.png',
      alt: 'midwest-vision-partners-Logo',
      link: 'https://midwestvision.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/Jazeera_Airways_logo.png',
      alt: 'JazeeraAirwaysLogo',
      link: 'https://www.jazeeraairways.com/en-in',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/Cordis.png',
      alt: 'cordis-logo.png',
      link: 'https://cordis.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/GCELogo.png',
      alt: 'greatcanadian-Logo',
      link: 'https://greatcanadian.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/yum-brand-logo.png',
      alt: 'yum-logo',
      link: 'https://www.yum.com/wps/portal/yumbrands/Yumbrands',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/WilliamsSonomaBrand.png',
      alt: 'WilliamSonomaLogo',
      link: 'https://www.williams-sonoma.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/pottery-barn-logo.png',
      alt: 'pottery-barn-logo',
      link: 'https://www.potterybarn.com/?cm_type=gnav',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/west-elm-logo.png',
      alt: 'west-elm-logo',
      link: 'https://www.westelm.com/?cm_type=gnav&cm_sp=GlobalLinks-_-Topnav-_-WestElmLogo',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/WorkwearLogo.png',
      alt: 'WorkwearOutfittersLogo',
      link: 'https://www.wwof.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/World-Market-Logo.png',
      alt: 'World-Market-Logo',
      link: 'https://www.worldmarket.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/SephoraLogo.png',
      alt: 'SephoraLogo',
      link: 'https://www.sephora.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/Lands_end_logo.png',
      alt: 'Lands_end_logo',
      link: 'https://www.landsend.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/TheContainerStoreBrand.png',
      alt: 'TheContainerStoreLogo',
      link: 'https://www.containerstore.com/welcome.htm',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/FULLBEAUTYBRANDS.png',
      alt: 'FULLBEAUTYBrandsLogo',
      link: 'https://www.fbbrands.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/Buckman_Logo.png',
      alt: 'Buckman_Logo',
      link: 'https://www.buckman.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/Western_Digital_logo.png',
      alt: 'Western_Digital_logo',
      link: 'https://www.westerndigital.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/CableLabsLogo.png',
      alt: 'CableLabsLogo',
      link: 'https://www.cablelabs.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/ADA-logo.png',
      alt: 'ADA-logo',
      link: 'https://diabetes.org/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/diligente-technologies-.png',
      alt: 'diligente-technologies_logo',
      link: 'http://www.diligentetechnologies.com/services',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/Kaiser_Permanente_logo.png',
      alt: 'KaiserPermanente-logo',
      link: 'https://healthy.kaiserpermanente.org/front-door',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/iot-logo.png',
      alt: 'iotnxt-logo',
      link: 'https://www.iotnxt.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/DriveWealthLogo.png',
      alt: 'DriveWealthLogo',
      link: 'https://www.drivewealth.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/TurnBiotecchnologiesBrand.png',
      alt: 'TurnBiotechnologiesLogo',
      link: 'https://www.turn.bio/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/eleven_eleven_System.png',
      alt: 'eleven_eleven_System_ogo',
      link: 'https://1111systems.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/Iress_logo.png',
      alt: 'iress-logo',
      link: 'https://www.iress.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/worldpay_logo.png',
      alt: 'worldpay-Logo',
      link: 'https://www.worldpay.com/en',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/rimini_street_logo.png',
      alt: 'RiminiStreetLogo',
      link: 'https://www.riministreet.com/',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/Synaptics-logo.png',
      alt: 'synaptics-Logo',
      link: 'https://www.synaptics.com/',
    },
  ]

  return (
    <LandingPageCarouselCustomersSliderWrapper>
      {/* Top Carousel (Scroll Left) */}
      <div className="CustomersCustomersSlider">
        <div className="CustomersCustomersSlide-Track">
          {[...images, ...images].map((image, index) => (
            <div className="CustomersSlide" key={index}>
              <a href={image.link} target="_blank" rel="noopener noreferrer">
                <img src={image.src} alt={image.alt} />
              </a>
            </div>
          ))}
        </div>
      </div>

      {/* Bottom Carousel (Scroll Right) */}
      <div className="CustomersCustomersSlider">
        <div className="CustomersCustomersSlide-Track-Reverse">
          {/* Reverse the duplicated images for the second carousel */}
          {[...images, ...images].reverse().map((image, index) => (
            <div className="CustomersSlide" key={index}>
              <a href={image.link} target="_blank" rel="noopener noreferrer">
                <img src={image.src} alt={image.alt} />
              </a>
            </div>
          ))}
        </div>
      </div>
    </LandingPageCarouselCustomersSliderWrapper>
  )
}

export default AppLandingPageCarousel
