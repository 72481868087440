import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { LinkHubspot } from './LinkHubspot'

const AppHomeNewsSectionWpper = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  height: auto;

  .NewsContain {
    max-width: 95vw;
    height: auto;
    margin: auto;
    /* background-image: linear-gradient(to top, #e3f0ff 0%, #f3e7e9 99%, #e3f0ff 100%); */
    /* background-image: radial-gradient(circle at 0 0, #e2fcff, #e5ecff 34%, #e4ecff 75%, #e2fcff); */
    background-image: url('https://storage.googleapis.com/raindroppublic/website_data/About_us_raindrop.png');
    background-position: center;
    background-repeat: no-repeat;
    /* background-color: #102c64; */
    background-size: cover;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 42px;
  }

  .NewsHeading {
    color: #fff;
    font-size: 2.4rem;
    margin-bottom: 0;
    @media (max-width: 1090px) {
      font-size: 1.5rem;
    }

    @media (max-width: 430px) {
      font-size: 1.5rem;
    }
  }

  .sliderWrapperWithArrows {
    position: relative;
    width: 70vw;
    margin: 0 auto;
    @media (max-width: 1920px) {
      width: 80vw;
    }
    @media (max-width: 1650px) {
      width: 80vw;
    }
    @media (max-width: 768px) {
      width: 60vw;
    }
  }

  .sliderContainer {
    overflow: hidden;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  .sliderWrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .slide {
    margin: 0 8px;
    min-width: calc(33.33% - 16px);
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    @media (min-width: 1650px) and (max-width: 1920px) {
      min-width: calc(33.33% - 16px);
    }
    @media (max-width: 1024px) and (min-width: 768px) {
      min-width: calc(50% - 16px);
    }

    @media (max-width: 768px) {
      min-width: calc(100% - 16px);
    }
  }

  .sliderImage {
    width: 100%;
    height: auto;
    border-radius: 16px;
  }

  .sliderArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    font-weight: lighter;
    font-family: 'GothamHTF', 'Helvetica Neue', sans-serif;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    &:hover {
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 8px;
    }
  }

  .leftArrow {
    left: -50px;
  }

  .rightArrow {
    right: -50px;
  }

  .dots {
    text-align: center;
    padding-top: 10px;
  }

  .NewsPara {
    color: #fff;
    font-size: 1rem;
    padding-bottom: 24px;
    @media (max-width: 425px) {
      display: none;
    }
  }

  .NewsButton {
    margin-bottom: 12px;
    position: relative;
    padding: 12px 28px;
    background-color: #34a1d5;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    font-size: 1.2rem;
    z-index: 1;
    transition: background 0.3s linear;
    font-weight: lighter;
    font-family: 'GothamHTF', 'Helvetica Neue', sans-serif;

    &:focus {
      outline: none;
    }

    &:hover {
      background: #004991;
      transition: background 0.3s ease-in;
      cursor: pointer;
      animation: animate 8s linear infinite;

      &::before {
        filter: blur(20px);
        opacity: 1;
        animation: animate 8s linear;
      }
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: 400%;
      border-radius: 25px 0 0 25px;
      opacity: 0;
      transition: 0.5s;
    }
  }

  @keyframes animate {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }
`

export const AppHomeNewsSection = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [sliderWidth, setSliderWidth] = useState(3) // Default to 3 for large screens

  const images = [
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/GoogleCloud-Marketplace.jpg',
      link: '/resources',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/IDCNew-raindrop.jpg',
      link: 'https://raindrop.com/solutions/SourcingEvents',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/SpendMatters-Validated-raindrop.jpg',
      link: 'https://www.linkedin.com/posts/raindropsystemsinc_rfi-sourcing-spendanalytics-activity-7242608870404096000-kxjM/?utm_source=share&utm_medium=member_ios',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/Gartner-raindrop.jpg',
      link: 'resources/gartner',
    },
    {
      src: 'https://storage.googleapis.com/raindroppublic/website_data/Everest-Group-raindrop.jpg',
      link: '/resources',
    },
    { src: 'https://storage.googleapis.com/raindroppublic/website_data/SpendMatters-raindrop.jpg', link: '/resources' },
    { src: 'https://storage.googleapis.com/raindroppublic/website_data/CIO-Review-raindrop.jpg', link: '/resources' },
    { src: 'https://storage.googleapis.com/raindroppublic/website_data/IDC-raindrop.jpg', link: '/resources' },
  ]

  const numOfDots = 6

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % numOfDots)
    }, 5000)

    // Adjust slider width on the client side
    const adjustSliderWidth = () => {
      if (window.innerWidth < 768) {
        setSliderWidth(1)
      } else if (window.innerWidth < 1024) {
        setSliderWidth(2)
      } else {
        setSliderWidth(3)
      }
    }

    if (typeof window !== 'undefined') {
      adjustSliderWidth()
      window.addEventListener('resize', adjustSliderWidth)
    }

    return () => {
      clearInterval(interval)
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', adjustSliderWidth)
      }
    }
  }, [numOfDots])

  return (
    <AppHomeNewsSectionWpper>
      <div className="NewsContain">
        <div className="NewsHeading">Recognition</div>
        <p className="NewsPara">Read about the latest Raindrop Recognition.</p>

        <div className="sliderWrapperWithArrows">
          <button
            className="sliderArrow leftArrow"
            onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex - 1 + numOfDots) % numOfDots)}
          >
            &#10094;
          </button>
          <div className="sliderContainer">
            <div
              className="sliderWrapper"
              style={{
                transform: `translateX(-${currentImageIndex * (100 / sliderWidth)}%)`,
              }}
            >
              {images.map((image, i) => (
                <div className="slide" key={i}>
                  <LinkHubspot to={image.link}>
                    <img src={image.src} alt={`Slide ${i + 1}`} className="sliderImage" />
                  </LinkHubspot>
                </div>
              ))}
            </div>
          </div>
          <button
            className="sliderArrow rightArrow"
            onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex + 1) % numOfDots)}
          >
            &#10095;
          </button>
        </div>

        <div className="dots">
          <LinkHubspot to="/resources">
            <button className="NewsButton" onClick={() => console.log('Learn More clicked')}>
              Learn More
            </button>
          </LinkHubspot>
        </div>
      </div>
    </AppHomeNewsSectionWpper>
  )
}
