import styled from 'styled-components'
import React from 'react'

const AppHomeByFunctionCardsWrapper = styled.div`
  main {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-template-rows: auto; /* Auto rows */
    gap: 10px; /* Gap between cards */
    justify-content: center;
    padding: 50px;
    font-family: 'Roboto', sans-serif;
    max-width: 1440px; /* Limit the width */
    margin: 0 auto; /* Center the grid on large screens */

    @media (max-width: 768px) {
      grid-template-columns: 1fr; /* One column on tablets */
      padding: 20px;
    }

    @media (max-width: 480px) {
      padding: 10px;
      gap: 10px;
    }
  }

  .card {
    width: 42rem;
    height: 24rem;
    border-radius: 24px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    box-shadow: rgba(226, 226, 226, 0.16) 0px 1px 4px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    background-color: #f5f5f5; /* Default light background */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0; /* No padding to the right */

    @media (max-width: 1024px) {
      width: 100%; /* Full width for tablets and below */
    }

    img.icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 120px; /* Adjust icon size */
      width: auto; /* Maintain aspect ratio */
      margin-right: 0; /* Ensure no space on the right */
      @media (max-width: 425px) {
        top: 20%;
      }
    }

    p,
    a {
      position: absolute;
      opacity: 0;
      max-width: 80%;
      transition: opacity 0.3s ease-out;
      color: #6e6e6e;
      font-weight: lighter;
    }

    p {
      inset: auto auto 50px 30px;
      font-size: 15px;
      font-weight: lighter;
    }

    a {
      inset: auto auto 40px 30px;
      color: inherit;
      text-decoration: none;
      color: #6e6e6e;
      font-weight: lighter;
    }

    &:hover p,
    &:hover a {
      opacity: 1;
      transition: opacity 0.5s 0.1s ease-in;
      color: #6e6e6e;
      font-weight: lighter;
    }

    &:hover img {
      transition: opacity 0.3s ease-in;
      opacity: 1;
    }
  }

  /* Apply different background colors for each card */
  .card:nth-child(1) {
    background-color: #d4e6fd; /* Light blue */
  }

  .card:nth-child(2) {
    background-color: #f3e7e9; /* Light pink */
  }

  .card:nth-child(3) {
    background-color: #ececec; /* Light green */
  }

  .card:nth-child(4) {
    background-color: #e3f6f7; /* Light yellow */
  }

  .material-symbols-outlined {
    vertical-align: middle;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    .card p {
      font-size: 15px; /* Set font size to 14px for screens between 768px and 1024px */
      font-weight: lighter;
    }
  }
  @media (min-width: 1440px) {
    main {
      width: 100vw; /* Make sure it takes the full width of the viewport */
      padding-left: 50px; /* Adjust padding to match 1440px layout */
      padding-right: 50px;
    }

    .card {
      width: 47vw; /* You can also set a max width for each card to prevent overflow */
      margin: 0 auto; /* Center the cards horizontally */
    }

    .card p {
      font-size: 18px; /* Set font size to 14px for screens between 768px and 1024px */
      font-weight: lighter;
    }
  }

  .card h2 {
    position: absolute;
    top: 162px;
    left: 50%; /* Center the heading initially */
    transform: translateX(-50%); /* Ensure the heading is centered */
    margin: 0;
    transition: left 0.3s ease-out, transform 0.3s ease-out;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    color: #1f1f1f;
    font-weight: lighter;
  }

  .card p {
    position: absolute;
    opacity: 0;
    max-width: 80%;
    transition: opacity 0.3s ease-out;
    color: #6e6e6e;
    bottom: 50px;
    left: 30px; /* Left-aligned */
    font-weight: lighter;
    @media (max-width: 425px) {
      font-size: 14px;
      font-weight: bolder;
    }
  }

  .card:hover h2 {
    left: 30px; /* Move the h2 to the same left position as the p tag */
    transform: none; /* Remove the translate to stop it from centering */
  }
`

export const AppHomeByFunctionCards = () => {
  return (
    <AppHomeByFunctionCardsWrapper>
      <main>
        <div className="card">
          <div className="card-content">
            <h2>Finance</h2>
            <p>
              Raindrop empowers Finance teams to optimize cost control, improve invoice efficiency, ensuring enhanced
              supplier management, compliance, reporting, integration, user-friendliness, payment options, risk
              management, and scalability.
            </p>
            <img
              className="icon"
              src="https://storage.googleapis.com/raindroppublic/website_data/raindrop-finance.png"
              alt="Finance Icon"
            />
          </div>
        </div>
        <div className="card">
          <div className="card-content">
            <h2>Procurement</h2>
            <p>
              Raindrop provides strategic supplier collaboration, sourcing automation, contract management, and spend
              visibility, for dynamic performance tracking, and workflow management, with user-friendly interfaces.
            </p>
            <img
              className="icon"
              src="https://storage.googleapis.com/raindroppublic/website_data/raindrop-procurement.png"
              alt="Procurement Icon"
            />
          </div>
        </div>
        <div className="card">
          <div className="card-content">
            <h2>Technology</h2>
            <p>
              Get quick integration with Raindrop's platform for effective data security, scalability, user experience,
              customization, analytics, support, mobile access, compliance, and automation in S2P systems.
            </p>
            <img
              className="icon"
              src="https://storage.googleapis.com/raindroppublic/website_data/raindrop-technology.png"
              alt="Technology Icon"
            />
          </div>
        </div>
        <div className="card">
          <div className="card-content">
            <h2>Legal</h2>
            <p>
              GenAI and AI-powered Raindrop platform automates and streamlines contract management, compliance
              monitoring, audit trails, risk assessment, templates, collaboration tools, dispute tracking, and strong
              data security.
            </p>
            <img
              className="icon"
              src="https://storage.googleapis.com/raindroppublic/website_data/raindrop-Legal.png"
              alt="Legal Icon"
            />
          </div>
        </div>
      </main>
    </AppHomeByFunctionCardsWrapper>
  )
}
