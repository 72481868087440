import React from 'react'
import styled from 'styled-components'
import { LinkHubspot } from './LinkHubspot'

const AppHomeAboutUsWrapper = styled.div`
  padding: 0px 0px 54px 0px;
  text-align: center;
  background-image: url('https://storage.googleapis.com/raindroppublic/website_data/About_raindrop.jpg');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  color: #ffffff; /* White text for contrast */
  border-radius: 42px;
  overflow: hidden; /* Ensures child elements respect border radius */
  margin: auto;
  width: 50%;
  width: 95vw;

  .AppHomeAboutUsHeading {
    padding: 12px 0px 12px 0px;
    font-size: 3rem;
    font-weight: bold;
    margin: 2rem 0 1rem;
    color: #ffffff;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .AppHomeAboutUsContainer {
    max-width: 80vw;
    margin: auto;
    background-color: #f5f5f5;
    border-radius: 28px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 2rem;
    overflow: hidden; /* Keeps the image within the border radius */
    flex-wrap: nowrap;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .TextSection {
    flex: 1;
    text-align: left;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
      padding: 1rem;
      text-align: center; /* Center-align the text */
      justify-content: center;
      align-items: center; /* Align content horizontally */
    }
  }

  .AppHomeAboutUsHeadingInner {
    color: #1f1f1f;
    font-size: 2.4rem;
    margin-bottom: 0.5rem;

    @media (max-width: 1090px) {
      font-size: 1.5rem;
    }
    @media (max-width: 425px) {
      display: none;
    }
  }

  .AppHomeAboutUsPara {
    color: #5f5f5f;
    font-size: 1rem;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
      text-align: center; /* Center-align the paragraph */
    }
    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }

  .AppHomeAboutUsButton {
    position: relative;
    padding: 12px 28px;
    background-color: #34a1d5;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    font-size: 1.2rem;
    transition: background 0.3s linear;
    font-weight: lighter;
    font-family: 'GothamHTF', 'Helvetica Neue', sans-serif;

    &:hover {
      background: #004991;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      align-self: center; /* Center-align the button */
    }
  }

  .ImageSection {
    flex: 0 0 40%; /* Fix width to 40% */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; /* Ensures the image fills the available space */
      border-top-right-radius: 42px;
      border-bottom-right-radius: 42px;

      @media (max-width: 768px) {
        height: auto;
        border-radius: 0; /* Remove radius for stacking layout */
      }
    }
  }
`

export const AppHomeAboutUs = () => {
  return (
    <AppHomeAboutUsWrapper>
      <h1 className="AppHomeAboutUsHeading">About Us</h1>
      <div className="AppHomeAboutUsContainer">
        <div className="TextSection">
          <h2 className="AppHomeAboutUsHeadingInner">Raindrop the AI-Powered Platform Driving Cost Savings</h2>
          <p className="AppHomeAboutUsPara">
            We started with a simple question. Why can’t we get all of our procurement and contracts information in one
            place at one time? When we set out to answer that question, Raindrop was born. We are the single solution
            where it counts most – where you spend your money.
          </p>
          <LinkHubspot to="/about">
            <button className="AppHomeAboutUsButton" onClick={() => console.log('Read More clicked')}>
              Learn More
            </button>
          </LinkHubspot>
        </div>
        <div className="ImageSection">
          <img
            src="https://storage.googleapis.com/raindroppublic/website_data/Raindrop_team_2024-55.jpg"
            alt="Office"
          />
        </div>
      </div>
    </AppHomeAboutUsWrapper>
  )
}
