import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import MainBanner from '../Data/MainBanner.json'
// import { SignUpButton } from './SignUpButton'
// import { DemoRequestButton } from './DemoRequestButton'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { AppModalSlider } from './AppModalSlider'
import AppLandingPageCarousel from './AppLandingPageCarousel'
import { SignUpLandingPageButton } from './SignUpLandingPageButton'
// import { DemoRequestButton } from './DemoRequestButton'

const AppHomeBannerWapper = styled.div`
  .MainSection {
    height: auto;
    position: relative;
    padding-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: linear-gradient(180deg, #ffffff 25%, rgb(41, 128, 185) 100%);
    @media (max-width: 1450px) {
      padding-top: 18vh;
    }
    @media (max-width: 550px) {
      padding-top: 25vh;
    }
  }
  .HomeBannerImage {
    height: auto;

    @media (min-width: 1000px) {
      height: 80vh;
    }
    @media (max-width: 1090px) {
      display: none;
    }

    @media (min-width: 1680px) {
      height: 80vh;
    }

    @media (min-width: 1920px) {
      height: 80vh;
    }
    @media (min-width: 2560px) {
      height: auto;
    }
  }
  .ParaSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5rem 0 0 0;

    @media (max-width: 1090px) {
      padding: 60px 15px 20px 15px;
      width: 100vw;
    }
  }

  .HomaMainHeading {
    color: #34a1d5;
    font-size: 4rem;
    font-weight: bolder;
    max-width: 900px;
    animation: 1s ease slideInFromBottom;
    margin-bottom: 0;
    padding-bottom: 0;

    @keyframes slideInFromBottom {
      from {
        transform: translateY(100px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @media (max-width: 650px) {
      font-size: 2rem;
    }
  }

  .HomaMainPara {
    padding-top: 12px;
    margin-top: 0;
    color: #6e6e6e;
    max-width: 580px;
    line-height: 25px;
    font-size: 1rem;
    animation: 1.2s ease slideInFromBottom;

    @media (max-width: 1300px) {
      max-width: 800px;
      padding-top: 1rem;
      line-height: 30px;
    }
    @media (max-width: 1200px) {
      max-width: 550px;
    }
  }

  .twobuttonbar {
    display: flex;
    /* justify-content: center; */
    padding-top: 2rem;
    animation: 1.4s ease slideInFromBottom;

    @media (max-width: 650px) {
      flex-wrap: wrap;
    }
  }

  .DemoButton {
    margin: 0 10px;
  }

  .HomeBannerDesktopImage {
    /* margin-top: 2rem; */
    height: auto;

    @media (max-width: 1090px) {
      display: none;
    }

    @media (min-width: 1000px) {
      height: 80vh;
      margin-left: 0;
    }

    @media (min-width: 1680px) {
      height: -webkit-fill-available;
      margin-left: 0;
    }

    @media (min-width: 1920px) {
      height: 80vh;
      margin-left: 0;
    }
    @media (min-width: 2560px) {
      height: auto;
      margin-left: 0;
    }
  }

  /* .SingUPButton {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000; 
    margin-right: 10px;

    @media (max-width: 650px) {
      position: fixed;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1000;
      margin-right: 10px;
    }
  } */
  .HomeBannerMobileImage {
    display: none;
    @media (max-width: 1090px) {
      display: flex;
      justify-content: center;
      margin: auto;
      height: 60vh;
    }
    @media (max-width: 425px) {
      height: 50vh;
    }
  }
  .OnLeave {
    width: 100%;
    height: 100;
    margin: auto;
    outline: none;
    background-color: #fff;
    border: none;
  }
  .HomaMainHeadingSecondPart {
    color: #1f1f1f;
  }
  .SignUpLandingPageButton {
    writing-mode: vertical-lr;
    position: relative;

    @media (max-width: 768px) {
      display: none;
    }
  }
`

export const AppHomeBanner = () => {
  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setTimeout(() => {
      setShowModal(true)
    }, 100)
  }

  useEffect(() => {
    openModal()
  }, [])

  const signupEvent = () => {
    trackCustomEvent({
      category: 'Special Button',
      action: 'Click',
      label: 'sign up',
    })
  }

  return (
    <>
      <AppHomeBannerWapper>
        {MainBanner.map((data, index) => (
          <div key={'MainBanner_' + index} className="MainSection">
            <div className="ParaSection">
              <h1 className="HomaMainHeading">
                AI-POWERED <span className="HomaMainHeadingSecondPart">ENTERPRISE SPEND MANAGEMENT</span>
              </h1>
              <p className="HomaMainPara">{data.shortPara}</p>
              {/* <div className="twobuttonbar">
                <div className="DemoButton">
                  <DemoRequestButton label="Talk To Us" />
                </div>
              </div> */}
            </div>
            <div className="HomeBannerDesktopImage">
              <img src={data.ImagePath} className="HomeBannerImage" alt="Home Banner" />
            </div>
            <img src={data.MobileViewBannerImage} className="HomeBannerMobileImage" alt="Mobile Banner" />
            <div className="ModalSlider">
              <AppModalSlider showModal={showModal} setShowModal={setShowModal} />
            </div>
            <div className="LandingPageCarousel">
              <AppLandingPageCarousel />
            </div>
          </div>
        ))}
        <div className="SignUpLandingPageButton">
          {/* <SignUpButton label="Get for free" onClick={signupEvent} /> */}
          <SignUpLandingPageButton label="Get Started" onClick={signupEvent} />
        </div>
      </AppHomeBannerWapper>
    </>
  )
}
