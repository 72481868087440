import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const AppHomeAwardSectionWpper = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 40px;
  text-align: center; /* Center the heading and slider */

  .awardContain {
    max-width: 95vw;
    margin: auto;
    background-color: #102c64;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 42px;
  }

  .awardHeading {
    color: #fff;
    font-size: 2rem;
    margin-bottom: 2rem;
    @media (max-width: 1090px) {
      font-size: 1.5rem;
    }

    @media (max-width: 430px) {
      font-size: 0.9rem;
    }
  }

  .sliderContainer {
    width: 60vw; /* Adjust width to fit three images */
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    @media (max-width: 430px) {
      width: 90vw;
    }
  }

  .sliderWrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .slide {
    min-width: 33.33%; /* Show three images */
    box-sizing: border-box;
  }

  .sliderImage {
    width: 100%;
    height: auto;
  }

  .dots {
    text-align: center;
    padding-top: 10px;
  }

  .dot {
    height: 9px;
    width: 9px;
    margin: 0 5px;
    border: 2px solid #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease, transform 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.4);
      background-color: #bbb;
    }
  }

  .active {
    background-color: #bbb;
    transform: scale(1.4);
  }
`

export const AppHomeAwardSection = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const images = [
    'https://storage.googleapis.com/raindroppublic/website_data/Award-amazon-business.png',
    'https://storage.googleapis.com/raindroppublic/website_data/Award-Google-Cloud-marketplace.png',
    'https://storage.googleapis.com/raindroppublic/website_data/Award-CIOReview.png',
    'https://storage.googleapis.com/raindroppublic/website_data/Award-IDC.png',
    'https://storage.googleapis.com/raindroppublic/website_data/Award-SpendMatters-2.png',
    'https://storage.googleapis.com/raindroppublic/website_data/Award-SpendMatters-4.png',
    'https://storage.googleapis.com/raindroppublic/website_data/Award-SpendMatters-3.png',
    'https://storage.googleapis.com/raindroppublic/website_data/Award-SpendMatters-1.png',
  ]

  const numOfDots = 6 // Restrict dots to 6

  // Automatically change slides every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % numOfDots)
    }, 5000)

    // Cleanup interval on component unmount
    return () => clearInterval(interval)
  }, [numOfDots])

  const handleDotClick = (index: number) => {
    setCurrentImageIndex(index)
  }

  return (
    <AppHomeAwardSectionWpper>
      <div className="awardContain">
        <div className="awardHeading">Raindrop Is Recognized As The Industry Leader</div>

        <div className="sliderContainer">
          <div
            className="sliderWrapper"
            style={{
              transform: `translateX(-${currentImageIndex * (100 / 3)}%)`, // Adjusted for 3 images per screen
            }}
          >
            {images.map((image, i) => (
              <div className="slide" key={i}>
                <img src={image} alt={`Slide ${i + 1}`} className="sliderImage" />
              </div>
            ))}
          </div>
        </div>

        <div className="dots">
          {/* Render only 6 dots */}
          {Array.from({ length: numOfDots }).map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentImageIndex ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>
    </AppHomeAwardSectionWpper>
  )
}
